<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('Supplier profile') }}
    </p>
    <section class="content-margin">
      <div class="d-flex align-items-center mb-15 filter-block">
        <label>{{ $t('Select a category') }}</label>
        <!-- <div class="icon-input mx-10">
          <input type="text" class="input" />
          <img src="/assets/img/icons/search-light.svg" alt="">
        </div> -->
        <input
          v-if="isCreate" 
          type="text" 
          class="input profile-dropdown"
          v-model="newProfile" 
        />
        <vue-select
          v-else
          :options="profile.options"
          class="input cus-select profile-dropdown"
          v-model="profile.selected"
          @input="changeProfile"
        ></vue-select>
        <button 
          class="primary-btn btn-limit-width new-profile-btn bg-yellow"
          v-if="!isCreate"
          @click="startCreateLogic"
        >
          {{ $t('New profile') }}
        </button>
      </div>
      <div class="mb-20" v-if="isCreate || selectedProfile">
        <label class="mb-2">{{ $t('Approved for use for') }}</label>
        <vue-select
          v-if="!isCreate"
          :options="roles.options"
          class="input cus-select cus-multi-dropdown"
          v-model="roles.selected"
          multiple
        ></vue-select>
        <vue-select
          v-else
          :options="roles.options"
          class="input cus-select cus-multi-dropdown"
          v-model="rolesForCreate.selected"
          multiple
        ></vue-select>
      </div>

      <div class="px-7 mb-15" v-if="isCreate || selectedProfile">
        <div class="fee-block d-flex align-items-center flex-wrap">
          <div class="d-flex align-items-center">
            <v-checkbox color="#0D3856" class="cus-checkbox default-profile" v-model="is_default" id="is_default"></v-checkbox>
            <label class="mx-5" for="is_default">{{ $t('As a default profile') }}</label>
          </div>
          <div class="d-flex align-items-center">
            <v-checkbox color="#0D3856" class="cus-checkbox default-profile" v-model="is_shaniv_default" id="is_shaniv_default"></v-checkbox>
            <label class="mx-5" for="is_shaniv_default">{{ $t('As shaniv default') }}</label>
          </div>

          <button v-if="!isCreate && selectedProfile" :disabled="!selectedProfile" class="primary-btn primary-btn_sm mx-15" @click="saveProfileFlags">
            {{ $t('Save') }}
          </button>
        </div>
      </div>

      <div class="d-flex justify-content-between column-tb-blocks px-7" v-show="isCreate || selectedProfile">
        <div class="column-tb w-100">
          <ColumnTable2 
            v-if="!isCreate && selectedProfile"
            :table_title="$t('Check All')" 
            :table_data.sync="selectedProfile.Suppliers"
          />
          <ColumnTable2 
            v-if="isCreate"
            :table_title="$t('Check All')" 
            :table_data.sync="supplierList" 
          />
        </div>
      </div>
      <div class="d-flex" v-if="isCreate || selectedProfile">
        <button :disabled="!isCreate && !selectedProfile" class="primary-btn btn-limit-width m-7" @click="save()">
          {{ $t("Save") }}
        </button>
        <button 
          v-if="isCreate"  
          class="primary-btn btn-limit-width m-7 bg-red1" 
          @click="cancelCreate()"
        >
          {{ $t("Cancel") }}
        </button>
        <button v-if="!isCreate && selectedProfile"
          class="primary-btn btn-limit-width bg-red1 m-7" 
          @click="openDeleteConfirm"
        >
          {{ $t("Delete") }}
        </button>
      </div>
    </section>
    <ConfirmModal @confirmData="confirmData">
      <template v-slot:modal-content>
        {{ $t('Are you sure you want to delete {NAME}?', {NAME: selectedProfile ? selectedProfile.name : ""}) }}
      </template>
    </ConfirmModal>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main> 
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ColumnTable2 from '../../../components/ColumnTable2'
import ConfirmModal from "../../../components/management/ConfirmModal.vue"

export default {
  name: 'CPSupplierProfiles',
  components: {
    ColumnTable2,
    ConfirmModal
  },
  data() {
    return {
      profile: {
        options: [],
        selected: ""
      },    
      roles: {
        options: [],
        selected: []
      }, 
      rolesForCreate: {
        selected: []
      },   
      isCreate: false,
      newProfile: "",
      supplierList: [],
      is_default: false,
      is_shaniv_default: false,
    }
  },
  computed: {
    ...mapState({
      supplierProfile: (state) => state.category_products.supplierProfile,
      supplierProfileData: (state) => state.category_products.supplierProfileData,
      rolesList: (state) => state.seller_tourism_percentage.rolesList,
      supplier_items: (state) => state.supplier.supplier_items,
      loading: (state) => state.seller_tourism_percentage.loading,
    }),
    selectedProfile() {
      let selectedProfile = null;

      if (this.supplierProfileData && this.supplierProfileData.length > 0
        && this.profile && this.profile.selected && this.profile.selected.code === this.supplierProfileData[0].id) {
        selectedProfile = this.supplierProfileData[0];
      }

      return selectedProfile;
    }
  },
  async created() {
    if (this.supplierProfile.length == 0) {
      await this.getSupplierProfile();
    }

    if (this.supplierProfile.length != 0) {
      await this.createProfileDropdown();
    }

    if (this.rolesList.length == 0) {
      await this.getRoles();
    }

    if (this.rolesList.length != 0) {
      this.roles = {
        options: [],
        selected: ""
      };
      this.rolesList.forEach(el => {
        this.roles.options.push({
          label: el.name,
          code: el.id,
        });
      });

      this.roles.selected = [];
    }

    this.changeProfile();

  },
  methods: {
    ...mapActions("category_products", {
      getSupplierProfile: "getSupplierProfile",
      getSupplierProfileData: "getSupplierProfileData",
      updateSupplierProfileData: "updateSupplierProfileData",
      createSupplierProfileData: "createSupplierProfileData",
      deleteCommissionProfile: "deleteCommissionProfile"
    }), 
    ...mapActions("seller_tourism_percentage", {
      getRoles: "getRoles",
      updateProfileFlags: "updateProfileFlags"
    }), 
    ...mapActions("supplier", {
      getAllSupplierItems: "getAllSupplierItems"
    }),
    async clearForm() {
      this.roles.selected = [];
      this.supplierList = [];
      this.is_default = false;
      this.is_shaniv_default = false;
    },
    async changeProfile() {
      await this.clearForm();
      if (!this.profile || !this.profile.selected || !this.profile.selected.code) {
        return;
      }
      let profile_code = this.profile.selected.code;
      await this.getSupplierProfileData({
        id: profile_code
      });
      if (this.selectedProfile) {
        if (this.selectedProfile.Roles && this.selectedProfile.Roles.length > 0) {
          this.selectedProfile.Roles.forEach(el => {
            this.roles.selected.push({
              code: el.BusinessRole.role_id,
              label: el.name
            });
          });
        }
        this.is_default = this.selectedProfile.is_default;
        this.is_shaniv_default = this.selectedProfile.is_shaniv_default;
      }
    },
    async createProfileDropdown(id = null) {
      this.profile = {
        options: [],
        selected: ""
      };
      this.supplierProfile.forEach(el => {
        this.profile.options.push({
          label: el.name,
          code: el.id,
        });
      });

      if (this.profile.options && this.profile.options.length > 0) {
        let profileToSelect = this.profile.options[0];
        if (id) {
          profileToSelect = this.profile.options.find(p => p.code === id) || profileToSelect;
        }
        this.profile.selected = {...(profileToSelect)};
        this.profile = {...(this.profile)};
      }
      await this.changeProfile();
    },
    async save() {
      let tempRoles = [];
      let tempSuppliers = {};

      if (!this.isCreate) {
        if (!this.selectedProfile) {
          return;
        }
        this.roles.selected.forEach(el => {
          tempRoles.push(el.label);
        });

        if (this.selectedProfile && this.selectedProfile.Suppliers) {
          this.selectedProfile.Suppliers.forEach(el => {
            tempSuppliers[el.supplier_id] = el.BusinessSupplier.is_authorized;
          })
        }

        const params = {
          name: this.selectedProfile.name,
          is_default: !!this.is_default,
          is_shaniv_default: !!this.is_shaniv_default,
          roles: tempRoles,
          suppliers: tempSuppliers
        };

        await this.updateSupplierProfileData({
          id: this.selectedProfile.id,
          params: params
        });

        this.changeProfile();
      } else {
        this.rolesForCreate.selected.forEach(el => {
          tempRoles.push(el.label);
        });

        this.supplierList.forEach(el => {
          tempSuppliers[el.supplier_id] = el.BusinessSupplier.is_authorized;
        });

        const params = {
          name: this.newProfile,
          is_default: !!this.is_default,
          is_shaniv_default: !!this.is_shaniv_default,
          roles: tempRoles,
          suppliers: tempSuppliers
        };

        await this.createSupplierProfileData({
          params: params
        }).then(async res => {
          if (res && res.message == "success") {
            let createdProfileId = res && res.id ? res.id : null;
            await this.getSupplierProfile();
            await this.createProfileDropdown(createdProfileId);
          }
        });
        this.isCreate = false;
      }
    },

    cancelCreate() {
      this.isCreate = false;
    },
    async startCreateLogic() {
      await this.clearForm();
      this.newProfile = "";
      this.isCreate = true;

      this.rolesForCreate.selected = [];
      
      if (Object.keys(this.supplier_items).length == 0) {
        await this.getAllSupplierItems();
      }
      if (Object.keys(this.supplier_items).length != 0) {
        for (const key in this.supplier_items) {
          this.supplier_items[key].forEach(el => {
            this.supplierList.push({
              BusinessSupplier: {
                is_authorized: false
              },
              name: el.name,
              supplier_id: el.id
            })
          });
        }
      }
    },
    async confirmData() {
      if (!this.selectedProfile) {
        return;
      }
      const profile_id = this.selectedProfile.id;
      await this.deleteCommissionProfile(profile_id);
      this.$modal.hide('confirmModal');
      await this.getSupplierProfile();
      await this.createProfileDropdown();
    },
    openDeleteConfirm() {
      if (!this.selectedProfile) {
        return;
      }
      this.$modal.show('confirmModal');
    },
    async saveProfileFlags() {
      if (!this.selectedProfile) {
        return;
      }
      const profile_id = this.selectedProfile.id;
      const params = {
        business_id: profile_id,
        is_default: !!this.is_default,
        is_shaniv_default: !!this.is_shaniv_default
      };
      await this.updateProfileFlags({params: params});

      this.changeProfile();
    },
  }
}
</script>

<style lang="scss" scoped>
.fee-block {
  background: #E5E5E5;
  border: 1px solid #D4D4D4;
  padding: 10px;
  label {
    height: unset;
  }
}
.new-profile-btn {
  height: 38px;
}

@media screen and (max-width: 1024px) {
  .column-tb-blocks {
    flex-wrap: wrap;
    .column-tb {
      width: 100%;
    }

  }
}
@media screen and (max-width: 608px) {
  .filter-block {
    flex-wrap: wrap;
    button {
      margin-top: 10px;
    }
  }
}
</style>