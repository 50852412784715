<template>
  <div class="column-table w-100">
    <div class="th-div d-flex align-items-center p-2">
      <label>{{ table_title }}</label>
      <v-checkbox color="#0D3856" class="cus-checkbox ml-5 mr-5"  @change="changeState" v-model="selectAll"></v-checkbox>
    </div>
    <perfect-scrollbar>
      <div class="tbody-div d-flex p-2">
        <div class="tr-div d-flex justify-content-between align-items-center fs16 bg-white" v-for="item in table_data" :key="item.supplier_id">
          <label>{{ item.name }}</label>
          <v-checkbox color="#0D3856" class="cus-checkbox" @change="changeState"  v-model="selected"  :value="item.supplier_id" ></v-checkbox>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: 'ColumnTable2',
  props: {
    table_title: String,
    table_data: Array
  },
  data() {
    return {
      check_all: false,
      selected: []
    }
  },
  watch: {
    table_data: function(val) {
      if (val.length !=0) {
        let selected = [];
        val.forEach(function (item) {
          if (item.BusinessSupplier.is_authorized) {
            selected.push(item.supplier_id);
          }
        });
        this.selected = selected;
        // this.$emit("update:table_data", newvalue)
      }
    }
  },
  computed: {
    selectAll: {
      get: function () {
        return this.table_data ? this.selected.length == this.table_data.length : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.table_data.forEach(function (item) {
            selected.push(item.supplier_id);
          });
        }

        this.selected = selected;
      }
    }
  },
  methods: {
    changeState() {
      const selected = this.selected;
      this.table_data.forEach(function (item) {
        if (selected.includes(item.supplier_id)) {
          item.BusinessSupplier.is_authorized = true;
        } else {
          item.BusinessSupplier.is_authorized = false;
        }
      });
    }
  },
  mounted() {
    if (this.table_data.length !=0) {
        let selected = [];
        this.table_data.forEach(function (item) {
          if (item.BusinessSupplier.is_authorized) {
            selected.push(item.supplier_id);
          }
        });
        this.selected = selected;
      }
  }
}
</script>

<style lang="scss" scoped>
.column-table {
  .tbody-div {
    flex-wrap: wrap;
    .tr-div {
      border-bottom: 1px solid #CACACA;
      width: 18%;
      margin: 0 1%;
      padding: 0 5px;
      @media screen and (max-width: 1024px) {
        width: 23%;
      }
      @media screen and (max-width: 900px) {
        width: 48%;
      }
      @media screen and (max-width: 600px) {
        width: 98%;
      }
    }
  }
}
.ps {
  height: 400px;
}

</style>